<template>
  <header id="appHeader" class="pc-header">
    <div class="pc-header__info">
      <div class="pc-header__container">
        <Contacts />

        <Socials />
      </div>
    </div>

    <div class="pc-header__main">
      <div class="pc-header__container pc-header__main-container">
        <a href="https://pionercup.ru/" class="pc-header__logo">
          <img :src="themeImages['logo-inverse']" alt="pionercup-logo" class="pc-header__logo-img">
        </a>

        <a v-if="isLive" :href="$env.Live" class="pc-header__live">
          <span class="pc-header__live-dot"></span>
          <div class="pc-header__live-text">Life трансляция</div>

          <div class="pc-header__live-arrow">
            <svg width="33" height="8" viewBox="0 0 33 8" xmlns="http://www.w3.org/2000/svg">
              <path d="M32.3536 4.35355C32.5488 4.15829 32.5488 3.84171 32.3536 3.64645L29.1716 0.464466C28.9763 0.269204 28.6597 0.269204 28.4645 0.464466C28.2692 0.659728 28.2692 0.976311 28.4645 1.17157L31.2929 4L28.4645 6.82843C28.2692 7.02369 28.2692 7.34027 28.4645 7.53553C28.6597 7.7308 28.9763 7.7308 29.1716 7.53553L32.3536 4.35355ZM6.5485e-10 4.5L32 4.5L32 3.5L-6.5485e-10 3.5L6.5485e-10 4.5Z" />
            </svg>
          </div>
        </a>

        <div class="pc-header__main-menu">
          <div class="pc-header__menu-item pc-header__menu-item_dropdown">
            <span>О&nbsp;кубке</span>
            <div class="pc-header__menu-dropdown">
              <a href="https://pionercup.ru/about">Информация</a>
              <a href="https://pionercup.ru/rules">Правила</a>
              <a href="https://pionercup.ru/help">Помощь</a>
              <a href="https://pionercup.ru/contacts">Контакты</a>
            </div>
          </div>

          <a class="pc-header__menu-item" href="https://pionercup.ru/registration">Регистрация</a>

          <div class="pc-header__menu-item pc-header__menu-item_dropdown">
            <span>Участникам</span>
            <div class="pc-header__menu-dropdown">
              <a href="https://results.pionercup.ru/">Результаты</a>
              <a href="https://pionercup.ru/rating">Рейтинг</a>
              <a href="https://photo.pionercup.ru/">Ваши фото</a>
            </div>
          </div>

          <div class="pc-header__menu-item pc-header__menu-item_divider"></div>

          <a class="pc-header__menu-item" href="https://reg.pionercup.ru/Profile">Личный кабинет</a>
        </div>
      </div>

      <div class="pc-header__menu-burger" @click="toggleMobileMenu">
        <img src="./Menu.svg" alt="burger">
      </div>
    </div>

    <img class="pc-header__border" src="./Border.svg">

    <div class="pc-header__menu-mobile" :class="{ 'pc-header__menu-mobile_open': menuOpen }">
      <div class="pc-header__mobile-top">
        <div class="pc-header__mobile-menu-header">
          <a href="https://pionercup.ru/" class="pc-header__logo">
            <img :src="themeImages.logo" alt="pionercup-logo" class="pc-header__logo-img">
          </a>

          <div class="pc-header__menu-burger" @click="toggleMobileMenu">
            <img src="./Close.svg" alt="burger">
          </div>
        </div>

        <a v-if="isLive" :href="$env.Live" class="pc-header__live">
          <span class="pc-header__live-dot"></span>
          <div class="pc-header__live-text">Life трансляция</div>

          <div class="pc-header__live-arrow">
            <svg width="33" height="8" viewBox="0 0 33 8" xmlns="http://www.w3.org/2000/svg">
              <path d="M32.3536 4.35355C32.5488 4.15829 32.5488 3.84171 32.3536 3.64645L29.1716 0.464466C28.9763 0.269204 28.6597 0.269204 28.4645 0.464466C28.2692 0.659728 28.2692 0.976311 28.4645 1.17157L31.2929 4L28.4645 6.82843C28.2692 7.02369 28.2692 7.34027 28.4645 7.53553C28.6597 7.7308 28.9763 7.7308 29.1716 7.53553L32.3536 4.35355ZM6.5485e-10 4.5L32 4.5L32 3.5L-6.5485e-10 3.5L6.5485e-10 4.5Z" />
            </svg>
          </div>
        </a>

        <div class="pc-header__section-items">
          <div class="pc-header__section-item">
            <div class="pc-header__section-title">О&nbsp;кубке</div>
            <div class="pc-header__menu-items">
              <a href="https://pionercup.ru/about">Информация</a>
              <a href="https://pionercup.ru/rules">Правила</a>
              <a href="https://pionercup.ru/help">Помощь</a>
              <a href="https://pionercup.ru/contacts">Контакты</a>
            </div>
          </div>

          <div class="pc-header__section-item">
            <div class="pc-header__section-title">Участникам</div>
            <div class="pc-header__menu-items">
              <a href="https://results.pionercup.ru/">Результаты </a>
              <a href="https://pionercup.ru/rating">Рейтинг</a>
              <a href="https://photo.pionercup.ru/">Ваши фото</a>
            </div>
          </div>
        </div>

        <div class="pc-header__additional-links">
          <a class="pc-header__section-title" href="https://pionercup.ru/registration">Регистрация</a>
          <a class="pc-header__section-title" href="https://reg.pionercup.ru/Profile">Личный кабинет</a>
        </div>
      </div>

      <div class="pc-header__mobile-bottom">
        <Contacts />

        <Socials />
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import eventsApi from '@/services/events-api';
import Socials from './Socials.vue';
import Contacts from './Contacts.vue';

export default {
  components: {
    Socials,
    Contacts,
  },
  data() {
    return {
      isLive: false,
      menuOpen: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'themeImages']),
  },
  methods: {
    toggleMobileMenu() {
      this.menuOpen = !this.menuOpen;
    },

    async getLiveCount() {
      const result = await eventsApi.getLiveCount();
      if (result.data > 0) {
        this.isLive = true;
      }
    },
  },
};
</script>

<style lang="scss">
@import './Header.scss';
</style>
